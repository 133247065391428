<template>
  <div class="qualifiers-marks">
    <v-row>
      <v-col> <v-breadcrumbs :items="breadCrumb" large></v-breadcrumbs> </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-simple-table class="elevation-1 level1 ">
          <template v-slot:default>
            <thead>
              <tr>
                <th class="text-left">
                  {{ $t("Student Name") }}
                </th>
                <th
                  class="text-left"
                  v-for="(item, index) in headers"
                  :key="index"
                >
                  {{ item.title }} {{ index + 1 }} ({{ item.total_mark }})
                </th>
                <!-- <th class="text-center">{{ $t("Total") }}</th> -->
              </tr>
            </thead>
            <tbody>
              <tr v-for="item in items" :key="item.name">
                <td>{{ item.student_name }}</td>
                <td v-for="(val, cellIndex) in item.values" :key="cellIndex">
                  {{ val }}
                </td>

                <!-- <td>{{ calculateTotal(item) }}</td> -->
              </tr>
            </tbody>
          </template>
        </v-simple-table>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import axios from "axios";

export default {
  name: "qualifiersMarks",
  data() {
    return {
      headers: [],
      items: [],
      selectedPeriod: "",
      selectedGrade: "",
      selectedClass: "",
      selectedSubject: "",
      selectedQuarter: "",
      selectedQualifier: "",
      breadCrumb: [],
      classTitle: "",
      items: [
        // {
        //   name: "Frozen Yogurt",
        //   calories: 159,
        // },
        // {
        //   name: "Ice cream sandwich",
        //   calories: 237,
        // },
        // {
        //   name: "Eclair",
        //   calories: 262,
        // },
        // {
        //   name: "Cupcake",
        //   calories: 305,
        // },
        // {
        //   name: "Gingerbread",
        //   calories: 356,
        // },
        // {
        //   name: "Jelly bean",
        //   calories: 375,
        // },
        // {
        //   name: "Lollipop",
        //   calories: 392,
        // },
        // {
        //   name: "Honeycomb",
        //   calories: 408,
        // },
        // {
        //   name: "Donut",
        //   calories: 452,
        // },
        // {
        //   name: "KitKat",
        //   calories: 518,
        // },
      ],
      classTitle: "",
    };
  },
  methods: {
    // calculateTotal(item) {
    //   let total = 0;
    //   let count = item.values.length;
    //   item.values.forEach((val) => {
    //     total += Number(val);
    //   });

    //   return Math.round((total / count) * 100) / 100;
    // },
    getResults() {
      axios
        .get(
          this.getApiUrl +
            "/educational-history/admin/overview/subject/qualifier/show?academic_period_id=" +
            this.selectedPeriod +
            "&grade_id=" +
            this.selectedGrade +
            "&class_id=" +
            this.selectedClass +
            "&subject_id=" +
            this.selectedSubject +
            "&quarter_id=" +
            this.selectedQuarter +
            "&subjects_qualifier_id=" +
            this.selectedQualifier,

          {
            headers: {
              Authorization: "Bearer " + localStorage.token,
            },
          }
        )
        .then((response) => {
          console.log(response);
          this.headers = response.data.data.headers;
          this.items = response.data.data.students;
        });
    },
  },
  mounted() {
    this.selectedPeriod = this.$route.query.period_id;
    this.selectedQuarter = this.$route.query.quarter_id;
    this.selectedGrade = this.$route.query.grade_id;
    this.selectedClass = this.$route.query.class_id;
    this.selectedSubject = this.$route.query.subject_id;
    this.selectedQualifier = this.$route.query.qualifier_id;
    this.classTitle = this.$route.query.subjectTitle;

    let subjectName = this.$route.query.subject_name;
    this.classTitle = this.$route.query.subjectTitle;
    let adminViewHref =
      "/educationalhistory/adminView?period_id=" +
      this.selectedPeriod +
      "&quarter_id=" +
      this.selectedQuarter +
      "&grade_id=" +
      this.selectedGrade +
      "&class_id=" +
      this.selectedClass +
      "&class_name=" +
      this.$route.query.class_name;

    // academic_id=5&quarter_id=29&grade_id=16&class_id=48&class_name=Grade%2011%20-%20A&subject_id=230

    let subjectHref =
      "/educationalhistory/subjectview?academic_id=" +
      this.selectedPeriod +
      "&quarter_id=" +
      this.selectedQuarter +
      "&grade_id=" +
      this.selectedGrade +
      "&class_id=" +
      this.selectedClass +
      "&class_name=" +
      this.$route.query.class_name +
      "&subject_id=" +
      this.selectedSubject;

    this.breadCrumb.push({
      text: this.$i18n.t("Subjects Marks"),
      disabled: false,
      href: adminViewHref,
    });

    this.breadCrumb.push({
      text: subjectName,
      disabled: false,
      href: subjectHref,
    });

    this.breadCrumb.push({
      text: this.$route.query.qualifier_title,
      disabled: true,
      href: "#",
    });

    this.getResults();
  },
};
</script>

<style></style>
